
import Alpine from 'alpinejs';
import 'bootstrap';

window.Alpine = Alpine;

Alpine.start();

import jQuery from 'jquery';
window.$ = jQuery;


import EasyEditor from 'easyeditor';
window.EasyEditor = EasyEditor;

$(function () {
    if ($(".toast").length > 0) {
        setTimeout(() => {
            $(".toast").toast("hide");
        }, "5000");
    }

    $('[data-toggle="tooltip"]').tooltip();
});
